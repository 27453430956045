import React from 'react'
// @src imports
import { useAccount, useActions, useSelector } from 'src/hooks'
import { ProfileAvatar, Transition } from 'src/chrome'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'

import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import { TabBar } from 'src/design_system/components/TabBar/TabBar'
import { IconType } from 'src/design_system/atoms/icons/types'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { AccountRoute } from 'src/dashboard/routes/AccountRoute'
import { PaymentSettingsRoute } from 'src/dashboard/routes/PaymentSettingsRoute'
import { CardHistoryRoute } from 'src/dashboard/routes/CardHistoryRoute'
import { DraftedOrdersRoute } from 'src/dashboard/routes/DraftedOrdersRoute'
import { ShareSettingsRoute } from 'src/dashboard/routes/ShareSettingsRoute'
import { DashboardSettingsRoute } from 'src/dashboard/routes/Settings'
import {
  useNotificationCount,
  useReceivedPendingMembershipInvites,
} from 'src/react_query'

const styles = {
  dashboardHeader: {
    position: 'relative' as const,
    top: 0,
    left: 0,
    width: '100%',
    height: 'auto',
    paddingRight: '2rem',
    paddingLeft: '1rem',
    display: 'flex',
    flexDlow: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media (max-width: 815px)': {
      padding: 0,
    },
    '@media (max-width: 500px)': {
      paddingLeft: 20,
      '> div:last-child': {
        display: 'none',
      },
    },
  },
  headerSubtitle: {
    display: 'flex',
    alignItems: 'baseline',
    '@media (max-width: 1550px)': {
      flexDirection: 'column' as const,
    },
  },
  flexRowWrap: {
    display: 'flex',
    flexFlow: 'row wrap',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column' as const,
    '@media (max-width: 670px)': {
      alignItems: 'flex-start',
      paddingLeft: 20,
    },
    '@media (max-width: 500px)': {
      alignItems: 'center',
      paddingLeft: 0,
    },
  },
}

type DashboardHeaderProps = {
  routePath: string
  onOpenAccount: () => void
  toggleReminders: () => void
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  onOpenAccount,
  routePath,
  toggleReminders,
}) => {
  const actions = useActions()

  const { data: notificationCount } = useNotificationCount()
  const {
    data: pendingMembershipInvites,
  } = useReceivedPendingMembershipInvites()

  const notificationsCounter =
    (notificationCount ?? 0) + (pendingMembershipInvites?.length ?? 0)

  const tabs: {
    onClick: () => void
    title: string
    icon: IconType
    routePath?: string
  }[] = [
    {
      onClick: () => actions.openAccount(),
      title: 'Home',
      icon: 'home',
      routePath: AccountRoute.path,
    },
    {
      onClick: () => actions.openPaymentSettings(),
      title: 'Plans & Payment',
      icon: 'payment',
      routePath: PaymentSettingsRoute.path,
    },
    {
      onClick: () => actions.openCardHistory(),
      title: 'Card History',
      icon: 'card_history',
      routePath: CardHistoryRoute.path,
    },
    {
      onClick: () => actions.openDraftedOrders(),
      title: 'My Drafts',
      icon: 'drafts',
      routePath: DraftedOrdersRoute.path,
    },
    {
      onClick: () => actions.openShareSettings(),
      title: 'Sharing',
      icon: 'share_settings',
      routePath: ShareSettingsRoute.path,
    },
    {
      onClick: () => actions.openDashboardSettingsRoute(),
      title: 'Settings',
      icon: 'settings',
      routePath: DashboardSettingsRoute.path,
    },
    {
      onClick: () => toggleReminders(),
      title: 'Notifications',
      icon: 'notifications',
    },
  ]
  const matchTabToRoutPath = tabs.findIndex(tab => tab.routePath === routePath)
  const defaultTabIndex = matchTabToRoutPath < 0 ? 0 : matchTabToRoutPath
  const account = useAccount()
  const window = useSelector(state => state.window)
  const shouldHideTabBarText = window.width <= 1145
  const isMobileNavBreakPoint = window.width >= 950
  const displayTabs = isMobileNavBreakPoint
    ? tabs.slice(0, tabs.length - 1)
    : tabs

  return (
    <Flex css={styles.dashboardHeader}>
      {isMobileNavBreakPoint && (
        <Text content="Dashboard" type="subtitle" weight="bold" />
      )}

      <div css={styles.flexRowWrap}>
        <Flex columnGap="x4" inset={{ vertical: 'x1' }} justifyContent="center">
          <TabBar
            tabs={displayTabs}
            activeTabIndex={defaultTabIndex}
            activeTabBackgroundColor="#BE9CDD"
            activeTabTextColor="#FFF"
            shouldHideText={shouldHideTabBarText}
          />
          {/* Notification Bubble */}
          {isMobileNavBreakPoint && (
            <Flex
              justifyContent="center"
              alignItems="center"
              backgroundColor="#f5f6f7"
              inset="x1"
              style={{ borderRadius: '100px' }}
            >
              <Div
                boxShadow={'mediumLight'}
                position="relative"
                inset={{ vertical: 'x1', horizontal: 'x1_5' }}
                borderRadius="100px"
                backgroundColor={'foreground'}
                display="flex"
                onClick={() => actions.openNotifications()}
                cursor={'pointer'}
                alignItems="center"
                flexDirection="row"
                columnGap="x_5"
              >
                {notificationsCounter && (
                  <Flex
                    position="absolute"
                    top="-10px"
                    right="-10px"
                    backgroundColor="#BE9CDD"
                    color="inverseHeading"
                    borderRadius={'100px'}
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      padding: '1px 10px',
                      fontFamily: 'Montserrat, san serif',
                    }}
                  >
                    {notificationsCounter}
                  </Flex>
                )}
                <Div position="relative" top="3px">
                  <Icon name="notifications" size={22} />
                </Div>
              </Div>
            </Flex>
          )}
        </Flex>
      </div>
      <Div onClick={onOpenAccount} cursor="pointer">
        <ProfileAvatar
          account={account}
          size={40}
          isEditable={false}
          style={{
            color: 'white',
            fontSize: '1.1rem',
            letterSpacing: 1,
          }}
        />
      </Div>
    </Flex>
  )
}

export default suspenseBoundary({
  component: DashboardHeader,
  unresolved: <Transition />,
  failure: error => `Failed loading: ${error}`,
})
