import { Flex, Icon, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Icon as DSIcon } from 'src/design_system/atoms/icons/components/Icon'
import { Button } from 'src/design_system/components/Button/Button'
import { useState } from 'src/hooks'
import { AnimatePresence, motion } from 'framer-motion'

export type BannerType = 'success' | 'warning' | 'prompt'

type Action = {
  primaryAction: { title: string; onClick: () => void }
}

export type CollapsibleBannerProps = {
  type: BannerType
  title: string
  onClose?: () => void
  bannerActions?: Action
  children?: React.ReactNode
  childrenWrapperType?: 'absolute' | 'relative'
  outset?: string
}

export const CollapsibleBanner = (props: CollapsibleBannerProps) => {
  const {
    type,
    title,
    onClose,
    bannerActions,
    children,
    childrenWrapperType = 'absolute',
    outset,
  } = props

  const [isOpen, setIsOpen] = useState(false)

  const themeColor = {
    success: { element: '#059669', background: '#E8FAF0' },
    warning: { element: '#F5A524', background: '#FEFCE8' },
    prompt: { element: '#006FEE', background: '#E6F1FE' },
  }[type]

  return (
    <Flex
      position="relative"
      width="100%"
      style={{
        fontFamily: 'Montserrat, san sanserif',
        boxShadow: '0px 4px 36px 0px rgba(0, 0, 0, 0.04)',
        borderRadius: isOpen ? '16px 16px 0 0' : '16px',
      }}
      flexDirection="column"
      outset={outset ?? { bottom: 'x2' }}
    >
      <Flex
        justifyContent="space-between"
        width="100%"
        inset="20px 16px"
        alignItems="center"
        zIndex={2}
        backgroundColor={themeColor.background}
        style={{ borderRadius: isOpen ? '16px 16px 0 0' : '16px' }}
      >
        <Flex alignItems="center" flexDirection="row" columnGap="x1">
          <DSIcon
            name={type === 'success' ? 'check' : 'warning'}
            color={themeColor.element}
            size={16}
          />
          <Text
            type="body"
            content={title}
            style={{
              color: themeColor.element,
              fontSize: '14px',
              fontWeight: 700,
            }}
          />
        </Flex>
        <Flex flexDirection="row" alignItems="center" columnGap="x1">
          {children && (
            <Button
              title={isOpen ? 'View Less' : 'View More'}
              backgroundColor="transparent"
              textColor={themeColor.element}
              textSize="12px"
              textWeight={700}
              onClick={() => setIsOpen(!isOpen)}
            />
          )}
          {bannerActions && (
            <Button
              title={bannerActions.primaryAction.title}
              backgroundColor={themeColor.element}
              textColor={themeColor.background}
              textSize="10px"
              textWeight={700}
              onClick={bannerActions.primaryAction.onClick}
            />
          )}
          {onClose && (
            <Flex
              backgroundColor={themeColor.element}
              inset="6px"
              style={{ borderRadius: '50px' }}
              justifyContent="center"
              alignItems="center"
              onClick={onClose}
              cursor="pointer"
            >
              <Icon name="close" size={10} primaryColor="inverseHeadingText" />
            </Flex>
          )}
        </Flex>
      </Flex>
      {children && (
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ y: -90, opacity: 0 }}
              animate={{
                y: childrenWrapperType === 'absolute' ? 10 : 0,
                opacity: 1,
                transition: { type: 'spring', bounce: 0.25 },
              }}
              exit={{ y: -90, opacity: 0 }}
              transition={{ duration: 0.2 }}
              style={{
                width: '100%',
                borderBottomRightRadius: '16px',
                borderBottomLeftRadius: '16px',
                padding: '20px 16px',
                backgroundColor: '#fff',
                position: childrenWrapperType,
                top: childrenWrapperType === 'absolute' ? '58px' : undefined,
                boxShadow: '0px 4px 36px 0px rgba(0, 0, 0, 0.04)',
              }}
            >
              {children}
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </Flex>
  )
}
