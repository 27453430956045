import { Div, Flex, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Button } from 'src/design_system/components/Button/Button'

type WelcomeProps = {
  graphicUrl: string
  title: string
  description: string
  action: {
    title: string
    onClick: () => void
    titleColor?: string
    backgroundColor?: string
  }
  backgroundColor?: string
  contentMaxWidth?: string
  isMobile?: boolean
  shouldStackGreeting?: boolean
}

export const Welcome: React.FC<WelcomeProps> = ({
  graphicUrl,
  title,
  description,
  action,
  backgroundColor,
  contentMaxWidth,
  isMobile,
  shouldStackGreeting,
}) => {
  return (
    <Flex
      backgroundColor={backgroundColor}
      borderRadius="20px"
      inset="16px 24px"
      width="100%"
    >
      <Flex
        width="100%"
        flexDirection={shouldStackGreeting ? 'column-reverse' : 'row'}
        alignItems={shouldStackGreeting ? 'space-evenly' : 'center'}
        justifyContent="space-evenly"
      >
        <Flex
          flexDirection="column"
          rowGap="x1"
          alignItems="center"
          justifyContent="center"
          id="welcome_content"
        >
          <Flex
            flexDirection="column"
            alignItems={shouldStackGreeting ? 'center' : 'start'}
            rowGap="x1"
            maxWidth={contentMaxWidth}
            justifyContent={shouldStackGreeting ? 'center' : 'start'}
            width="100%"
          >
            <Text
              content={title}
              type="largeBody"
              color="primaryHeading"
              alignment={shouldStackGreeting ? 'center' : 'left'}
              weight="bold"
              lineHeight={1.2}
              style={{ fontSize: isMobile ? '.975rem' : '1.125rem' }}
            />
            <Text
              type="caption"
              content={description}
              alignment={shouldStackGreeting ? 'center' : 'left'}
              lineHeight={1.4}
              style={{ fontSize: isMobile ? '.775rem' : '.875rem' }}
            />
            <Spacer space="x_75" />
            <Button
              title={action.title}
              textColor={action.titleColor ?? '#FFFFFF'}
              background={action.backgroundColor ?? '#424548'}
              onClick={action.onClick}
              borderRadius="8px"
              textSize="12px"
            />
          </Flex>
        </Flex>
        <Flex
          id="welcome_graphic_container"
          style={isMobile ? { transform: 'scale(.8)' } : {}}
        >
          <Div
            style={{
              backgroundRepeat: 'no-repeat',
              height: 185,
              width: 200,
              backgroundSize: 'contain',
              backgroundImage: `url(${graphicUrl})`,
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
