import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'

import { Button } from 'src/design_system/components/Button/Button'
import { useAccount, useContainerWidth } from 'src/hooks'

export const GratitudeMovementWidget = () => {
  const [containerRef, containerWidth] = useContainerWidth()
  const shouldShrink = containerWidth <= 163
  const account = useAccount()

  return (
    <Flex
      width="100%"
      flexDirection="column"
      backgroundColor="#fff"
      alignItems="center"
      borderRadius="20px"
      minWidth="220px"
      height="inherit"
      inset="x2"
    >
      <Div
        ref={containerRef}
        display="flex"
        width="100%"
        flexDirection="column"
        justifyContent="start"
        alignItems="start"
      >
        <Text
          type="body"
          content="Gratitude Movement Presentation"
          alignment="left"
          style={{
            fontSize: shouldShrink ? '.925rem' : '1.125rem',
            fontWeight: 700,
            fontFamily: 'Montserrat, san serif',
            color: '#404040',
          }}
        />
      </Div>
      <Flex
        width="100%"
        justifyContent="center"
        alignItems="center"
        height="100%"
        outset={{ vertical: 'x1' }}
      >
        <Flex
          style={{
            backgroundRepeat: 'no-repeat',
            borderRadius: '12px',
            width: 1280,
            height: 'auto',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage:
              'url(https://framerusercontent.com/images/FxactqvyScBs1JH9vz2ToLFQ.jpg)',
            position: 'relative',
            paddingTop: '40.9375%',
          }}
        />
      </Flex>
      <Flex alignItems="flex-end" width="100%" inset={{ vertical: 'x1' }}>
        <Button
          title="Go To Page"
          background="linear-gradient(90deg,#E7F1F7,#FDFAF5)"
          textColor="#404040"
          textSize={'14px'}
          width="100%"
          whiteSpace="nowrap"
          onClick={() => {
            window.location.href = `https://webinar.promptings.com/${account.genealogyId}`
          }}
        />
      </Flex>
    </Flex>
  )
}
