import React from 'react'
// @src imports
import { BottomNav, defaultItems } from 'src/chrome/BottomNav/BottomNav'
import CardHistoryManager from 'src/card_history/containers/CardHistoryManager'
import { AccountRoute } from 'src/dashboard/routes/AccountRoute'
import { DashboardRoute } from 'src/dashboard/routes/DashboardRoute'
import { DraftedOrdersRoute } from 'src/dashboard/routes/DraftedOrdersRoute'

import { InvitesRoute } from 'src/dashboard/routes/InvitesRoute'
import { NotificationsRoute } from 'src/dashboard/routes/NotificationsRoute'
import { PaymentSettingsRoute } from 'src/dashboard/routes/PaymentSettingsRoute'
import { PurchasePointsRoute } from 'src/dashboard/routes/PurchasePointsRoute'
import { CardHistoryRoute } from 'src/dashboard/routes/CardHistoryRoute'
import { DashboardHeader, DashboardNav } from 'src/dashboard/components'
// relative imports
import DashboardHomeView from '../Views/Home/Home'
import Invites from '../Invites/Invites'
import Notifications from '../Notifications/Notifications'
import AnnouncementBanners from '../../../marketing/AnnouncementBanners/AnnouncementBanners'
import styles from './styles'
import { PricingRoute } from 'src/marketing/plans/routes/PricingRoute'
import { LeaderBoardRoute } from 'src/dashboard/routes/LeaderBoardRoute'
import { LeaderBoardList } from 'src/dashboard/components/Leaderboard/LeaderBoardList'
import { PricingPage } from 'src/pricing_page/PricingPage'
import { DraftsView } from '../Views/Drafts/DraftsView'
import { PlansAndPaymentView } from '../Views/PlansAndPayment/PlansAndPayment'
import { ShareSettingsView } from '../Views/Sharing/ShareSettingsView'
import { ShareSettingsRoute } from 'src/dashboard/routes/ShareSettingsRoute'
import { DashboardSettings } from '../Views/Settings/DashboardSettings'
import { DashboardSettingsRoute } from 'src/dashboard/routes/Settings'

import {
  Dialog,
  Div,
  DropDownMenuProps,
  Flex,
} from '@sendoutcards/quantum-design-ui'
import { AccountInfoSettingsView } from '../Views/Information/AccountInfoSettingsView'
import { useState } from 'src/hooks'
import { DraftedCardsRoute } from 'src/dashboard/routes/DraftedCardsRoute'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'

import { sponsorParam } from 'src/dashboard/navigationData'
import { AccountFragment } from 'src/graphql/generated/graphql'

import SimpleDrawer from 'src/chrome/SimpleDrawer/SimpleDrawer'

export const dropDownMenuLinks = (
  account?: AccountFragment,
): DropDownMenuProps => {
  const sponsorLinkParam = account ? sponsorParam(account) : ''

  return {
    linkColumn: {
      title: '',
      links: [
        {
          title: (
            <Flex flexDirection="column">
              <Icon name="soc_logo" size={200} color="#000" />
              <Flex
                alignItems="center"
                style={{
                  fontFamily: 'Ubuntu',
                  fontStyle: 'italic',
                  fontSize: '14px',
                }}
                justifyContent="flex-end"
                flexDirection="row"
                columnGap="x_25"
              >
                The
                <Icon name="promptings_logo_full" size={90} color="#000" />
                Mechanism
              </Flex>
            </Flex>
          ),
          href: '/app',
          target: '_self',
        },
        {
          title: (
            <Flex flexDirection="column">
              <Icon name="promptings_logo_full" size={170} color="#000" />
              <Flex
                alignItems="center"
                style={{
                  fontFamily: 'Ubuntu',
                  fontStyle: 'italic',
                  fontSize: '14px',
                }}
                justifyContent="flex-end"
                flexDirection="row"
                columnGap="x_5"
              >
                Powered by
                <Icon name="soc_logo" size={120} color="#000" />
              </Flex>
            </Flex>
          ),
          onClick: () =>
            window.open(`https://www.promptings.com/${sponsorLinkParam}`),
          href: '',
          target: '_blank',
        },
      ],
    },
    top: 60,
    isOpen: false,
  }
}
type DashboardProps = {
  route: DashboardRoute
}
const routeViews = (
  route: DashboardRoute,
  toggleIsAccountDrawerOpen: () => void,
) => ({
  [AccountRoute.path]: (
    <DashboardHomeView toggleIsAccountDrawerOpen={toggleIsAccountDrawerOpen} />
  ),
  [DraftedOrdersRoute.path]: <DraftsView />,
  [DraftedCardsRoute.path]: <DraftsView />,
  [InvitesRoute.path]: <Invites />,
  [NotificationsRoute.path]: <Notifications />,
  [CardHistoryRoute.path]: (
    <CardHistoryManager
      route={route.subroute as CardHistoryRoute}
      initialSection={(route.subroute as CardHistoryRoute).section}
      initialFilters={(route.subroute as CardHistoryRoute).filters}
    />
  ),
  [PaymentSettingsRoute.path]: <PlansAndPaymentView />,
  [ShareSettingsRoute.path]: <ShareSettingsView />,
  [DashboardSettingsRoute.path]: <DashboardSettings />,
  [PurchasePointsRoute.path]: <PricingPage />,
  [PricingRoute.path]: <PricingPage />,
  [LeaderBoardRoute.path]: <LeaderBoardList />,
})
export type DashboardRoutes = keyof typeof routeViews

const Dashboard: React.FC<DashboardProps> = props => {
  const { route } = props
  const routePath = route.subroute.path

  const [shouldShowAccountDrawer, setShouldShowAccountDrawer] = useState<
    boolean
  >(false)

  const toggleShouldShowAccountDrawer = () => {
    setShouldShowAccountDrawer(!shouldShowAccountDrawer)
  }

  const currentRouteView = routeViews(route, toggleShouldShowAccountDrawer)[
    route.subroute.path
  ]

  const [isOpen, setIsOpen] = useState(false)
  return (
    <Div overflowX="scroll" position="relative">
      <Div css={styles.dashboardWrapper}>
        <DashboardHeader
          toggleReminders={() => setIsOpen(!isOpen)}
          routePath={routePath}
          onOpenAccount={() => toggleShouldShowAccountDrawer()}
        />
        <Div css={styles.dashboardContainer(routePath === '/account')}>
          <AnnouncementBanners />
          <Flex
            flexDirection={'column'}
            width="100%"
            overflowX="scroll"
            outset={{ bottom: '100px' }}
          >
            {currentRouteView}
            <Dialog isOpen={isOpen}>
              <Notifications />
            </Dialog>
          </Flex>
        </Div>

        <div css={styles.bottomNavWrapper}>
          <div css={styles.bottomNav}>
            <BottomNav
              items={defaultItems}
              rootCSS={styles.rootNavWrapper}
              drawerItems={<DashboardNav />}
            />
          </div>
        </div>
      </Div>
      {routePath && (
        <SimpleDrawer
          isOpen={shouldShowAccountDrawer}
          onStateControlClick={toggleShouldShowAccountDrawer}
        >
          <AccountInfoSettingsView />
        </SimpleDrawer>
      )}
    </Div>
  )
}

export default Dashboard
